
import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"

//import Loader from "common/src/components/Loader"
import FormField from "app/components/form/FormField"
import Button from "app/components/Button"
import Checkbox from "app/components/form/Checkbox"
import PhoneField from "app/components/form/PhoneField"
import FormFieldContainer from "app/components/form/FormFieldContainer"

import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { ReactComponent as IconInstagram } from "common/src/svg/instagram.svg"
import { ReactComponent as IconLinkedin } from "common/src/svg/linkedin.svg"

import useDictRef from "common/src/hooks/useDictRef"
import isEmail from "common/src/lib/form/validator/isEmail"
import hub from "common/src/hub"
import api from "app/api"

import required from "common/src/lib/form/validator/required"
import createValidator from "common/src/lib/form/createValidator"
import validateInstagramHandle from "common/src/lib/form/validator/instagramHandle"
import validateLinkedIn from "common/src/lib/form/validator/linkedInUrl"

import useFormData from "common/src/lib/form/useFormData"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import getDialCodes from "common/src/lib/getDialCodes"
import Select from "app/components/form/Select"




function prepareInstagramHandle(value) {

    value = (value || "").trim();

    if (!value) {
        return value;
    }

    if (value[0] === '@') {
        return value.substring(1);
    }

    if (value.toLowerCase().indexOf("http") === 0 ||
        value.toLowerCase().indexOf("instagram.com") === 0) {

        const match = value.match(/instagram\.com\/([^/]+)\//);
        return match ? match[1] : value;
    }

    return value;
}

function prepareLinkedinUrl(value) {
    if (!value.match(/^http/i)) {
        return "https://" + value;
    }
    return value;
}

async function phoneRequired(value) {
    return await required(value?.number);
}

async function validateEmail(email) {
    const res = await api.unauth.post("/pse/validate-email", { body: { email } });
    return res?.success || false;
}

async function validateEmailMatch(value, _1, _2, values) {
    return value === values["email"];
}


const fields = [
    {
        name: "name",
        validator: createValidator(required, "Please enter your name")
    },
    {
        name: "surname",
        validator: createValidator(required, "Please enter your surname")
    },
    {
        name: "email",
        validator: [
            createValidator(required, "Please enter your email"),
            createValidator(isEmail, "Please enter a valid email"),
            createValidator(validateEmail, "Sorry, you can't use this email")
        ]
    },
    {
        name: "email2",
        validator: [
            createValidator(required, "Please confirm your email address"),
            createValidator(validateEmailMatch, "Email addresses don't match"),
        ]
    },
    {
        name: "phone",
        validator: createValidator(phoneRequired, "Please enter your phone number"),
        default: () => ({
            dialCode: "44",
            number: ""
        }),
    },
    {
        name: "country",
        validator: createValidator(required, "Please let us know where are you from")
    },
    {
        name: "company"
    },
    {
        name: "instagram",
        validator: createValidator(validateInstagramHandle, "Please enter a valid instagram handle")
    },
    {
        name: "linkedin",
        validator: [
            createValidator(validateLinkedIn, "Please enter a valid LinkedIn url")
        ]
    },
    {
        name: "bio"
    },
    {
        name: "agree",
        validator: createValidator(required, "You have to agree to our terms and conditions")
    }
]


function PSEForm({ useBack = false }) {

    const {
        validateAll, setErrorMode, resetAll,
        name, nameError, nameChange,
        surname, surnameError, surnameChange,
        email, emailError, emailChange,
        email2, email2Change, email2Error,
        phone, phoneError, phoneChange,
        country, countryError, countryChange,
        company, companyError, companyChange,
        instagram, instagramError, instagramChange,
        linkedin, linkedinError, linkedinChange,
        bio, bioError, bioChange,
        agree, agreeError, agreeChange
    } = useFormData(fields);

    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const [focusedField, setFocusedField] = useState(null);
    const [countries, setCountries] = useState([]);

    const ref = useDictRef({
        name, surname, country, email, agree, company,
        phone, instagram, linkedin, bio, focusedField
    });

    const onFieldFocus = useCallback(
        (e) => {
            setFocusedField(e.target.dataset.name)
        },
        []
    );

    const loadDialCodes = useCallback(
        async () => {
            const codes = await getDialCodes();
            const countryOptions = codes.map(c => ({
                name: c.code,
                label: c.name,
                value: c.name
            }));
            setCountries(countryOptions);
        },
        []
    );

    useEffect(
        () => {
            loadDialCodes();
        },
        // eslint-disable-next-line
        []
    );

    const onSubmit = useCallback(
        async () => {

            setSaving(true);

            const valid = await validateAll();
            if (valid) {

                const phoneNum = ref.phone.dialCode && ref.phone.number ?
                    ref.phone.dialCode + " " + ref.phone.number :
                    "";

                await api.unauth.post("/pse/apply", {
                    body: {
                        givenName: ref.name,
                        familyName: ref.surname,
                        country: ref.country,
                        email: ref.email,
                        company: ref.company || null,
                        phone: phoneNum,
                        bio: ref.bio,
                        createdVia: "website/shopper/form",
                        instagram: prepareInstagramHandle(ref.instagram),
                        linkedin: prepareLinkedinUrl(ref.linkedin)
                    }
                });

                ref.submitted = true;
                hub.dispatch("app", "show-shopper-form", false);
                hub.dispatch("alert", "show", {
                    overlay: true,
                    variant: "default-inverted",
                    title: "Thank you!",
                    message: "Thank you for submitting your application to join as a personal shopper " +
                        "on THE FLOORR! We will review your application and get back to " +
                        "you shortly via email."
                });

                hub.dispatch("posthog", "event", {
                    event: "pse_application_submitted",
                    payload: {
                        givenName: ref.name,
                        familyName: ref.surname,
                        city: ref.city,
                        email: ref.email,
                        withOptional: !!ref.company || !!ref.instagram || !!ref.bio
                    }
                });

                resetAll();
            }
            else {
                setErrorMode(true);
            }

            setSaving(false);
        },
        // eslint-disable-next-line
        []
    );

    const onCloseClick = useSwallowEventCallback(
        () => {
            if (!ref.submitted) {
                hub.dispatch("posthog", "event", {
                    event: "shopper_form_dropoff",
                    payload: {
                        focusedField: ref.focusedField
                    }
                })
            }
            if (useBack) {
                history.goBack();
            }
            else {
                hub.dispatch("app", "show-shopper-form", false)
            }
        },
        [useBack]
    );

    // const renderDialCode = useCallback(
    //     (e) => {
    //         console.log(e);
    //         return e
    //     },
    //     []
    // );

    return (
        <div className="connect-dialog-container">

            <div className="connect-dialog">

                <div className="connect-form">
                    <h2 className="h-primary connect-dialog-header">Personal Shopper</h2>
                    <p className="connect-dialog-subheader">If you are a Personal Shopper, please fill this form.
                        If you are a Client or if you are a Brand or a Retailer, please go back.</p>

                    <div className="form-row">
                        <FormField
                            name="name"
                            variant="transparent"
                            value={name}
                            error={nameError}
                            onSubmit={onSubmit}
                            onChange={nameChange}
                            inputProps={{
                                "data-name": "name",
                                onFocus: onFieldFocus,
                                placeholder: "Name *"
                            }} />
                    </div>
                    <div className="form-row">
                        <FormField
                            name="surname"
                            variant="transparent"
                            error={surnameError}
                            value={surname}
                            onSubmit={onSubmit}
                            onChange={surnameChange}
                            inputProps={{
                                "data-name": "surname",
                                onFocus: onFieldFocus,
                                placeholder: "Surname *"
                            }} />
                    </div>
                    <div className="form-row">
                        <FormField
                            name="email"
                            variant="transparent"
                            value={email}
                            error={emailError}
                            onSubmit={onSubmit}
                            onChange={emailChange}
                            inputProps={{
                                "data-name": "email",
                                onFocus: onFieldFocus,
                                placeholder: "Email address *"
                            }} />
                    </div>
                    <div className="form-row">
                        <FormField
                            name="email2"
                            variant="transparent"
                            value={email2}
                            error={email2Error}
                            onChange={email2Change}
                            inputProps={{
                                "data-name": "email",
                                onFocus: onFieldFocus,
                                placeholder: "Re-enter email address*"
                            }} />
                    </div>
                    <div className="form-row">
                        <FormFieldContainer error={phoneError}>
                            <PhoneField
                                name="phone"
                                variant="transparent"
                                value={phone}
                                onChange={phoneChange}
                                error={phoneError}
                                inputProps={{
                                    "data-name": "phone",
                                    onFocus: onFieldFocus,
                                    placeholder: "Phone Number *"
                                }} />
                        </FormFieldContainer>
                    </div>
                    <div className="form-row">
                        <FormFieldContainer error={countryError}>
                            <Select
                                name="country"
                                variant="transparent"
                                options={countries}
                                value={country}
                                error={countryError}
                                onSubmit={onSubmit}
                                placeholder="Location *"
                                onChange={countryChange} />
                        </FormFieldContainer>
                    </div>

                    <div className="form-row">
                        <FormField
                            name="company"
                            variant="transparent"
                            value={company}
                            error={companyError}
                            onSubmit={onSubmit}
                            onChange={companyChange}
                            inputProps={{
                                "data-name": "company",
                                onFocus: onFieldFocus,
                                placeholder: "Company Name"
                            }} />
                    </div>

                    <div className="form-row">
                        <FormField
                            name="instagram"
                            variant="transparent"
                            value={instagram}
                            error={instagramError}
                            onSubmit={onSubmit}
                            onChange={instagramChange}
                            right={<IconInstagram />}
                            inputProps={{
                                "data-name": "instagram",
                                onFocus: onFieldFocus,
                                placeholder: "Instagram Handle"
                            }} />
                    </div>

                    <div className="form-row">
                        <FormField
                            name="linkedin"
                            variant="transparent"
                            value={linkedin}
                            error={linkedinError}
                            onSubmit={onSubmit}
                            onChange={linkedinChange}
                            right={<IconLinkedin />}
                            inputProps={{
                                "data-name": "linkedin",
                                onFocus: onFieldFocus,
                                placeholder: "LinkedIn Url"
                            }} />
                    </div>

                    <div className="form-row">
                        <FormField
                            name="bio"
                            variant="transparent"
                            value={bio}
                            error={bioError}
                            onSubmit={onSubmit}
                            onChange={bioChange}
                            multiline
                            inputProps={{
                                "data-name": "bio",
                                onFocus: onFieldFocus,
                                placeholder: "Additional Info"
                            }} />
                    </div>


                    <div className="form-row">
                        <Checkbox
                            checked={agree}
                            error={agreeError}
                            onChange={agreeChange}
                            label={
                                <p className="connect-terms">
                                    By submitting this form you agree to our&nbsp;
                                    <Link to="/terms" target="_blank" rel="noreferrer noopener">
                                        <nobr>terms of use</nobr>
                                    </Link>&nbsp;
                                    and&nbsp;
                                    <Link to="/privacy" target="_blank" rel="noreferrer noopener">
                                        <nobr>privacy notice</nobr>
                                    </Link>.<br /><br />
                                </p>} />
                    </div>

                    <Button
                        text="Apply Now"
                        variant="white"
                        loading={saving}
                        onClick={onSubmit}
                        className="connect-action"
                        disabled={saving} />
                    <Button
                        text="Go Back"
                        variant="text"
                        className="connect-action-close"
                        onClick={onCloseClick} />
                </div>
            </div>

            <a href="/#" className="connect-dialog-close" onClick={onCloseClick}>
                <IconClose />
            </a>
        </div>
    )
}

export default PSEForm
