
import hub from "common/src/hub"
import Button from "./Button"

import { ReactComponent as DownloadIcon } from "common/src/svg/app-store-badge2.svg"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

function Hero() {

    //const [ showOptions, setShowOptions ] = useState(false);
    /*const onShowOptions = useSwallowEventCallback(
        () => setShowOptions(!showOptions),
        [ showOptions ]
    );*/

    const onPersonalShopperClick = useSwallowEventCallback(
        () => hub.dispatch("app", "show-shopper-form", true),
        []
    );
    // const onBrandClick = useSwallowEventCallback(
    //     () => hub.dispatch("app", "show-brand-form", true),
    //     []
    // );
    // const onCustomerClick = useSwallowEventCallback(
    //     () => hub.dispatch("app", "show-customer-form", true),
    //     []
    // );

    return (
        <div className="hero">

            <div className="hero-body">
                <h2 className="hero-title">The Personal Shopping Engine</h2>
                <p className="hero-subheader">Grow your personal shopping business with an app that's designed specifically for you.</p>
                <Button
                    text="Join now"
                    // variant="white"
                    className="hero-action"
                    onClick={onPersonalShopperClick}>
                </Button>
                <div className="hero-download">
                    <p>You can also join via our app:</p>
                    <a href="https://apps.apple.com/us/app/the-floorr-stylist/id6451112463">
                        <DownloadIcon />
                    </a>
                </div>
                {/*<div className="hero-options">
                    <a  href="/#" 
                        children="I Am A Brand Or Retailer" 
                        className="hero-option"
                        onClick={ onBrandClick }/>
                    <a  href="/#" 
                        children="I Am A Client" 
                        className="hero-option"
                        onClick={ onCustomerClick }/>
                </div>*/}
            </div>
        </div>
    )
}

export default Hero
